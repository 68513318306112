import React from 'react';
import { Link } from "react-router-dom";
import "./NotFound.scss";

function NotFound () 
{
    return (
        <div className="notFound" >
            <div className="image404">
                <img src="/images/404.png" alt="404 icon" />
            </div>
            <div className="content404">

                <h2 className="oops">OOPS!</h2>
                <p className="apology">We're sorry but we can't seem to find the page you're looking for.</p>

                <Link className="back" to="/">GO BACK HOME</Link>

            </div>
        </div>
    );
}

export default NotFound;
