import React from "react";
//import PropTypes from 'prop-types'
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useAppState } from "../../factory/AppFactory"
import CurrencyProvider from "../../helpers/Context";

export function AppLayout ( { children } )
{
    const { isHeaderShown } = useAppState()
  return (
    <div>
      {isHeaderShown && 
       <CurrencyProvider>
        <Header />
      </CurrencyProvider>
      }
        <div className="main" style={{marginTop:isHeaderShown ? 70 : 0}}>{children}</div>

      <Footer />
      {/* APP LEVEL NOTIFICATION COMPONENTS GOES HERE */ }
    </div>
  );
}

AppLayout.propTypes = {};
