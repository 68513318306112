import React from 'react';
import { appReducer } from './app.reducer';
var AppStateContext = React.createContext();
var AppDispatchContext = React.createContext();

function AppProvider({ children }) {
 
  var [state, dispatch] = React.useReducer(appReducer, {
    error: '',
    loading: false,
    isHeaderShown:true
  });
 
  //TODO-> a useEffect to check if a app is still valid
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState() {
  var context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error(
      'useAppState must be used within a appProvider',
    );
  }
  return context;
}

function useAppDispatch() {
  var context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAppDispatch must be used within a appProvider',
    );
  }
  return context;
}

export { AppProvider, useAppState, useAppDispatch };
