import React from "react";
import PageTitle from "./PageTitle";
import { AccordionList } from "../../component";
//import PropTypes from 'prop-types'
const data = [
  {

    title: "What is Afriswap?",
    content: "<p>Afriswap is an instant crypto platform that allows you to buy and sell crypto assets in minutes. No sign-up required, no login required.<p>"
  },
  {
    title: "How does Afriswap work?",
    content: `<p>On Afriswap, you simply select the amount of crypto you want to buy or sell; select your payment method and complete the transaction in minutes without the need to sign-up or login. Afriswap provides a seamless way to get started in crypto. <a href="/" target="_blank"> Click here to begin </a>.</p>
`},
  {
    title: "How do I buy Crypto on Afriswap?",
    content: `<section> 
    <p> Three steps to buy crypto on Afriswap </p>
    <br/>
      <ul>
          <li>Select crypto assets you wish to purchase.</li>
          <li>Enter your wallet address.</li>
          <li>Pay securely and receive crypto instantly.</li>
        </ul>
         <p><a href="/app/?Ttype=BUY&qstep=0" target="_blank"> Buy now </a></p>
    
    </section>`
  },
  {
    title: "How do I sell Crypto on Afriswap?",
    content: `<section> <p> Three steps to sell crypto on Afriswap </p>
    <br/>
      <ul>
          <li>Select crypto assets you wish to sell.</li>
          <li>Enter your bank account details.</li>
          <li>Send crypto to us and get credited instantly after the coin is confirmed.</li>
        </ul>

        <p><a href="/app/?Ttype=SELL&qstep=0" target="_blank"> Sell Now </a></p>
    </section>`
  },
  {
    title: "Are there maximum and minimum amounts?",
    content: "<p>Afriswap maintains is a minimum limit of ₦50,000 and a maximum limit of N1,000,000 to start trading. Reach us via our customer support or chat with us should you want to buy or sell crypto beyond the stated limits.</p>"
  },
  {
    title: "What is OTP?",
    content: "<p>OTP means a One Time Password and is a 6-digit number Afriswap sends to your mobile phone or email at the start of a transaction for the sole purpose of notifying you of the progress of your transaction from start to finish.</p>"
  },
  {
    title: "What payment methods can i use?",
    content: "<p>There are several payment methods available for you to use so you never miss out on your purchase. Payment methods include: Card, Bank Transfer, USSD, and QR with more options coming soon.</p>"
  },
  {
    title: "Are my card details safe?",
    content: "<p>Yes, very safe. Afriswap will never store your card details. Payments on Afriswap are handled by our payment partner, Paystack. Our payment processing partners are PCI DSS compliant which means that your card details are safe.</p>"
  },
  {
    title: "What country is Afriswap available in?",
    content: "<p>Afriswap is currently available in Nigeria. Expansion plans to other African countries are in the works. We will let you know as soon as we go live in other countries. Make sure you subscribe to our newsletter here.</p>"
  },
  {
    title: "Why should I trust you?",
    content: `<section> <p> Afriswap provides safe and fast transactions without revealing users' identities. We provide the best possible rates in the market and work with a list of crypto assets that are constantly increasing. More reasons to trust Afriswap: </p>

    <br/>

      <ul>
          <li>No coin/deposit storage</li>
          <li>Instant exchange</li>
          <li>No login required</li>
        </ul>
    </section>`
  },
  {
    title: "Why do I need to enter my email?",
    content: "<p>In order to buy or sell crypto, you will need to enter your email address or phone number. It is a necessary step for transaction identification required by our payment partners. Also, we need to notify you on the status of your transaction</p>"
  },
  {
    title: "What is a wallet address?",
    content: `<section><p>A wallet is a digital place where you can store crypto coins and tokens. A wallet address is a randomly generated combination of digits and letters that is associated with a particular wallet. 
    </p ></section ><section><p>
    Afriswap does not provide wallet addresses and never stores user deposits. To buy crypto on Afriswap, you need to provide a previously-existing wallet address where you can receive your purchased crypto.</p></section>`
  },
  {
    title: "How do I get a wallet address?",
    content: `<section>
    <p>First figure out what coin you want to buy and look for a stable compatible wallet. As a rule, each coin has its official wallet client.</p ></section > 
    
    <section>
    <p>Note that each wallet has its unique address or a tag with a private key that is required to restore your wallet if you lose it. Afriswap will never ask for your private keys. Store them in a safe place and never show them to anyone.</p ></section> `
  }
  ,
  {
    title: "How long before my crypto shows in my wallet?",
    content: "<p>Afriswap aims to deliver in minutes. As soon as your payment is confirmed, your crypto will be on its way to you. We will also notify you of the status via your email or mobile number.</p>"
  },
  {
    title: "How long do I have to wait to be paid after I sell crypto for fiat?",
    content: "<p>On Afriswap, you get credited in minutes. As soon as the crypto is confirmed with at least 3 confirmations, payment will be made to your bank account number provided. Our customer service is also available 24/7 to assist you where you need further assistance.</p>"
  },


];
function Faq ( props )
{
  return (
    <article className="info_article">
      <PageTitle title="FAQs" />
      <div className="inner faq">
        <section>
          <AccordionList data={ data } />
        </section>

      </div>
    </article>
  );
}

Faq.propTypes = {};

export default Faq;
