import React from 'react';
import { VictoryArea } from "victory";

function Chart ( { data } ) 
{
    return (
        <div>
            <svg style={ { height: 0 } }>
                <defs>
                    <linearGradient x1="0%" y1="0%" x2="0%" y2="100%" id="myGradient">
                        <stop offset="0%" stopColor="#3578E5" />

                        <stop offset="100%" stopColor="#1B3C7300" />
                    </linearGradient>
                </defs>
            </svg>


            <VictoryArea
                padding={ { top: 40, bottom: 40, left: 0, right: 0 } }
                interpolation="natural"
                style=
                {
                    {
                        data:
                        {
                            fill: "url(#myGradient)",
                            stroke: "#0595ff",
                            strokeWidth: 2,
                            strokeLinecap: "round"
                        }
                    }
                }
                data={ data }
            />
        </div>
    );
}

export default Chart;
