import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SwapCalculator } from "@afriswap/rate-converter";
import "@afriswap/rate-converter/dist/index.css";
import { Timeline, Chart, Slider } from "../../component";
import "./Home.scss";
import { history } from "../../helpers";
import CurrencyProvider, { CurrencyContext } from "../../helpers/Context";


function handleSwapSubmit ( { values, crypto, tType,locked } )
{
  history.push(
    `/app/?Ttype=${ tType }&fiatAmt=${ values.fiat }&q_quantity=${ values.crypto
    }&qCoin=${ crypto.code }&qInorder=${ true }&qstep=0&Qlocked=${locked}`
  );
}

const formatPrice = ( number ) =>
{
  const value = new Intl.NumberFormat( "en-GB" ).format( number );
  return `₦${ value }`;
};

const ChartList = ( { width } ) =>
{
  const { coins } = useContext( CurrencyContext );

  const items = coins.map( ( coin ) => (

    <div className="crypto_grid_rate__grid__item fadeInUp">
      <div className="info">
        <div className="info_title">
          <img src={ coin?.image } alt={ `${ coin?.currency } logo` } />
          <h2>{ coin?.currency }</h2>
        </div>
        <div className="current_rate">
          <p>{ formatPrice( coin?.price ) }</p>
          <small>
            <span className={ coin?.percentage > 0 ? "profit" : "loss" } >
              { coin?.percentage > 0 && "+" }{ coin?.percentage }% </span>
                (1 hr change)
                </small>
        </div>
      </div>
      <div className="graph">
        <Chart data={ coin?.items } />
      </div>
    </div>

  ) );

  return (
    width < 768 ?
      <Slider items={ items } />
      :
      (
        coins.map( ( coin, i ) => (
          <div key={ i } className="crypto_grid_rate__grid__item fadeInUp">
            <div className="info">
              <div className="info_title">
                <img src={ coin?.image } alt={ `${ coin?.currency } logo` } />
                <h2>{ coin?.currency }</h2>
              </div>
              <div className="current_rate">
                <p>{ formatPrice( coin?.price ) }</p>
                <small>
                  <span className={ coin?.percentage > 0 ? "profit" : "loss" } >
                    { coin?.percentage > 0 && "+" }{ coin?.percentage }% </span>
                (1 hr change)
                </small>
              </div>
            </div>
            <div className="graph">
              <Chart data={ coin?.items } />
            </div>
          </div>
        ) )
      )

  );
};

export default function Home ()
{
  const [ width, setWidth ] = useState( window.innerWidth );
  const [ mail, setMail ] = useState( "" );
  const [ state, setState ] = useState(
    {
      error: null,
      loading: false,
      hasSubmitted: false
    }
  );

  useEffect( () =>
  {
    const reset = () =>
    {
      if ( width !== window.innerWidth )
      {
        setWidth( window.innerWidth );
      }
    };

    window.addEventListener( "resize", reset );

    return () => window.removeEventListener( "resize", reset );

  }, [ width ] );

  const subscribe = async ( e ) =>
  {
    e.preventDefault();
    const isEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
      mail.trim()
    );

    if ( isEmail )
    {
      setState(
        {
          error: null,
          loading: true,
          hasSubmitted: false
        } );

      const data =
      {
        email: mail.trim(),
        isSubscribed: true
      };

      const url = "https://api.dev.afriswap.io/api/newsletter/subscribe";
      try 
      {
        const { ok } = await fetch( url,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( data )
          } );

        if ( ok )
        {
          setState(
            {
              error: null,
              loading: false,
              hasSubmitted: true
            } );
          setMail( "" );
        }
        else
        {
          setState(
            {
              error: "Unable to load data",
              loading: false,
              hasSubmitted: false
            } );
        }
      }
      catch ( error ) 
      {
        setState(
          {
            error: error.toString(),
            loading: false,
            hasSubmitted: false
          } );

      }
    }
    else
    {
      setState(
        {
          error: "Invalid email",
          loading: false,
          hasSubmitted: false
        } );
    }
  };

  const change = ( e ) =>
  {
    setMail( e.target.value );
  };

  return (
    <>
      <section className="hero_with_app">
        <div className="hero_with_app__text fadeInUp">
          <div className="text_wrap">
            <h1>
              Buy and sell Crypto in minutes. <span>No login required!</span>
            </h1>
            <p>
              Afriswap provides an easy, fast, and secure way to buy and sell
              your crypto in minutes.
            </p>
          </div>
        </div>
        <div className="hero_with_app__app fadeInUp">
          <SwapCalculator
            onSubmit={ handleSwapSubmit }
            no_shadow
            hideChat
            swapStyles={ {
              width: 464,
              maxWidth: "100%",
            } }
            show_logo={ false }
            activeTabStyle={ {
              background: "#fff",
              border: "3px solid #3578E5",
              color: "#022B69",
            } }
          />
        </div>
      </section>
      <section className="section crypto_grid_rate">
        <div className="crypto_grid_rate__grid">
          {/* PUT CHART HERE */ }
          <CurrencyProvider>
            <ChartList width={ width } />
          </CurrencyProvider>
        </div>

        {/* PUT CHART END */ }
      </section>
      <section className="section platform_offer">
        <h2 className="title fadeInUp">Why you should choose Afriswap</h2>
        <div className="grid_container first">
          <div className="grid_item">
            <img src="/images/arrow.svg" alt="low fees icon" />
            <h3>Transparent low fees</h3>
          </div>
          <div className="grid_item">
            <i className="fa fa-unlock-alt"></i>
            <h3>No Login required</h3>
          </div>
          <div className="grid_item">
            <img src="/images/sheild_check.svg" alt="secure icon" />
            <h3>Absolutely safe</h3>
          </div>
        </div>
      </section>
      <section className="steps section">
        <div className="steps__buy">
          <h2>Three steps to buy crypto</h2>
          <Timeline
            data={ [
              "Select crypto assets you wish to purchase.",
              "Enter your wallet address.",
              "Pay securely and receive crypto instantly.",
            ] }
          />
          <Link to="/app/?Ttype=BUY&qstep=0" className="cta">
            Buy Now
          </Link>
        </div>
        <div className="steps__sell">
          <h2>Three steps to sell crypto</h2>
          <Timeline
            data={ [
              "Select crypto assets you wish to sell.",
              "Enter your bank account details.",
              "Send crypto to us and get credited instantly.",
            ] }
          />
          <Link to="/app/?Ttype=SELL&qstep=0" className="cta">
            Sell Now
          </Link>
        </div>
      </section>
      <section className="section payments">
        <h2 className="title">Our payment methods</h2>
        <div className="methods">
          <div className="method_box">
            <img src="images/mastercard.svg" alt="master" />
            <p>Master Card</p>
          </div>
          <div className="method_box">
            <img src="images/visa.svg" alt="visa" />
            <p>Visa Card</p>
          </div>
          <div className="method_box">
            <img src="/images/verge-svg.svg" alt="verve" />
            <p>Verve Card</p>
          </div>
          <div className="method_box">
            <img src="images/transfer.svg" alt="transfer" />
            <p>transfer</p>
          </div>
          <div className="method_box">
            <img src="images/ussd.svg" alt="ussd" />
            <p>ussd</p>
          </div>
        </div>
      </section>
      <section className="section testimonials">
        <h2 className="title">What our wonderful customers have to say</h2>
        {
          width < 768 ?
            <Slider scale={ 0.75 } items={
              [ <div className="grid_item testimonial">
                <div className="customer_quote">
                  <p>
                    <img
                      src="/images/quote.svg"
                      className="quote_icon"
                      alt="quote_icon"
                    />
               I’ve always wanted a platform where I can buy and sell crypto without having to sign-up or login, Afriswap made it possible seamlessly. Keep up the good work!
              </p>
                </div>
                <div className="customer_info">
                  <img src="/images/img_avatar.png" alt="customer_image" />
                  <h4>Mercy Negedu</h4>
                </div>
              </div>
                , <div className="grid_item testimonial">
                <div className="customer_quote">
                  <p>
                    <img
                      src="/images/quote.svg"
                      className="quote_icon"
                      alt="quote_icon"
                    />
                Afriswap is reaally easy to use and their customer care is superb. They responded quickly to all the inquiries I had. I have a great experience using the service. You will too.
              </p>
                </div>
                <div className="customer_info">
                  <img src="/images/img_avatar.png" alt="customer_image" />
                  <h4>Moji A</h4>
                </div>
              </div>
                , <div className="grid_item testimonial">
                <div className="customer_quote">
                  <p>
                    <img
                      src="/images/quote.svg"
                      className="quote_icon"
                      alt="quote_icon"
                    />
                My transaction was completed within minutes and I was duly notified all the way on my mobile phone. Afriswap is going places, I strongly recommend them any time.
              </p>
                </div>
                <div className="customer_info">
                  <img src="/images/img_avatar.png" alt="customer_image" />
                  <h4>Michael B.</h4>
                </div>
              </div>
              ]
            } />
            :

            <div className="grid_container">
              <div className="grid_item testimonial">
                <div className="customer_quote">
                  <p>
                    <img
                      src="/images/quote.svg"
                      className="quote_icon"
                      alt="quote_icon"
                    />
               I’ve always wanted a platform where I can buy and sell crypto without having to sign-up or login, Afriswap made it possible seamlessly. Keep up the good work!
              </p>
                </div>
                <div className="customer_info">
                  <img src="/images/img_avatar.png" alt="customer_image" />
                  <h4>Mercy Negedu</h4>
                </div>
              </div>
              <div className="grid_item testimonial">
                <div className="customer_quote">
                  <p>
                    <img
                      src="/images/quote.svg"
                      className="quote_icon"
                      alt="quote_icon"
                    />
                Afriswap is reaally easy to use and their customer care is superb. They responded quickly to all the inquiries I had. I have a great experience using the service. You will too.
              </p>
                </div>
                <div className="customer_info">
                  <img src="/images/img_avatar.png" alt="customer_image" />
                  <h4>Moji A</h4>
                </div>
              </div>
              <div className="grid_item testimonial">
                <div className="customer_quote">
                  <p>
                    <img
                      src="/images/quote.svg"
                      className="quote_icon"
                      alt="quote_icon"
                    />
                My transaction was completed within minutes and I was duly notified all the way on my mobile phone. Afriswap is going places, I strongly recommend them any time.
              </p>
                </div>
                <div className="customer_info">
                  <img src="/images/img_avatar.png" alt="customer_image" />
                  <h4>Michael B.</h4>
                </div>
              </div>

            </div>

        }
      </section>
      <section className="section newsletter">
        { !state.hasSubmitted ? <h2 className="title">

          Get the latest news and promotions from Afriswap. Enter email to
          subscribe
        </h2> : <h2 className="title">
            Thanks for subscribing.
        </h2> }
        {
          !state.hasSubmitted ?
            <form onSubmit={ subscribe } className="input_form">
              <input
                onChange={ change }
                value={ mail }
                type="email"
                placeholder="Enter email..." />

              {
                state.error ? <p className="error">{ state.error }</p> : null
              }

              {
                !state.loading ? <button className="btn-launch">Subscribe</button>
                  : <p className="loading">Submitting, please wait</p>
              }

            </form>
            : null
        }
      </section>
    </>
  );
}
