import React from 'react'
//import PropTypes from 'prop-types'

function PageTitle({ title }) {
    return (
        <div className="page_title">
           <h1>{title}</h1> 
        </div>
    )
}

PageTitle.propTypes = {

}

export default PageTitle

