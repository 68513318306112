import React from 'react'
import PageTitle from "./PageTitle"
//import PropTypes from 'prop-types'

function Terms(props) {
    return (
    <article className="info_article">
        <PageTitle title="Terms and Conditions" />
        <div className="inner">
        <p>
          Welcome to Afriswap.io herein referred to as Afriswap. If you continue
          to browse and use Afriswap, you are agreeing to comply with and be bound
          by the following terms and conditions of use, which, together with our
          privacy policy, govern afriswap.io’s relationship with you in relation
          to all our applications and platforms. If you disagree with any part of
          these terms and conditions, please do not use any of our applications
          and platforms.
        </p>
        <ol className="terms_list">
          <p>
            The terms ‘afriswap.io' or ‘us’ or ‘we’ refer to the owner/s of
            Afriswap. The use of Afriswap is subject to the following terms of
            use:
          </p>
         <div>
            <li>
              The content of the pages of Afriswap is for your general information
              and use only. It is subject to change without notice.
            </li>
            <li>
              Neither we nor any third parties provide any warranty or guarantee
              as to the accuracy, timeliness, performance, completeness or
              suitability of the information and materials found or offered on
              Afriswap for any particular purpose. You acknowledge that such
              information and materials may contain inaccuracies or errors and we
              expressly exclude liability for any such inaccuracies or errors to
              the fullest extent permitted by law.
            </li>
            <li>
              Your use of any information or materials on Afriswap is entirely at
              your own risk, for which we shall not be liable. It shall be your
              own responsibility to ensure that any products, services or
              information available through Afriswap meet your specific
              requirements.
            </li>
            <li>
              Afriswap platforms contain material which is owned by or licensed to
              us. This material includes, but is not limited to, the design,
              layout, look, appearance and graphics. Reproduction is prohibited
              other than in accordance with the copyright notice, which forms part
              of these terms and conditions.
            </li>
            <li>
              All trademarks reproduced on Afriswap, which are not the property
              of, or licensed to the operator, are acknowledged on our
              applications and platforms. Unauthorised use of Afriswap may give
              rise to a claim for damages and/or be a criminal offence.
            </li>
            <li>
              From time to time, Afriswap applications and platforms may also
              include links to other websites, applications and/or platforms.
              These links are provided for your convenience to provide further
              information. They do not signify that we endorse the website(s),
              application(s) and/or platform(s). We have no responsibility for the
              content of the linked website(s), application(s) and/or platform(s).
            </li>
            <li>
              Afriswap reserves the right to make changes to our website,
              applications and/or platforms or any materials related to Afriswap
              without notice.
            </li>
            </div>
          </ol>
        </div>
      </article>
    )
}

Terms.propTypes = {

}

export default Terms

