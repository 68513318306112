import React, { useEffect, useState, createContext } from 'react';
import { min } from "d3-array";

export const CurrencyContext = createContext();

const extractData = ( data ) =>
{
    let items = [];


    for ( let index = 0; index < data.length - 1; index++ )
    {
        const diff = +data[ index + 1 ].tradingPairPrice.price - +data[ index ].tradingPairPrice.price;
        items.push(
            {
                x: index,
                y: diff

            }
        );

    }

    const y0 = min( items, d => d.y );

    //reverse it so that oldest prices come last;
    items = items.map( item => ( { ...item, y0 } ) ).reverse();
    const percentage = ( +data[ 0 ].tradingPairPrice.price - +data[ data.length - 1 ].tradingPairPrice.price ) * ( 100 / +data[ data.length - 1 ].tradingPairPrice.price );

    return {
        items,
        currency: data[ 0 ].crypto,
        image: data[ 0 ].cryptoLogoUrl,
        percentage: +percentage.toFixed( 2 ),
        price: +data[ 0 ].tradingPairPrice.price
    };
};


function CurrencyProvider ( { children } ) 
{
    const [ coins, setCoins ] = useState( [] );
    const [ rates, setRates ] = useState( [] );

    useEffect( () =>
    {
        const getData = async () =>
        {
            const base = "https://api.dev.afriswap.io/api/rates/exchange/buydata";

            const coinsUrl = "https://api.dev.afriswap.io/api/rates/exchange/buy";

            try 
            {
                const rates = [];
                let coins = await fetch( `${ coinsUrl }` );

                coins = await coins.json();

                const mapLoop = async _ =>
                {
                    const promises = coins.sort( ( a, b ) => ( a.crypto > b.crypto ) ? 1 : -1 ).map( async c =>
                    {
                        rates.push(
                            {
                                name: c.name,
                                crypto: c.crypto,
                                price: +c.tradingPairPrice.price
                            }
                        );
                        let data = await fetch( `${ base }/${ c.name }` );

                        data = await data.json();
                        data = extractData( data );

                        c = { ...c, ...data };

                        return c;
                    } );
                    setRates( rates );
                    const results = await Promise.all( promises );
                    setCoins( results );

                };

                mapLoop();

            }
            catch ( error ) 
            {
                console.error( error );
            }
        };

        getData();

        //update every 30s
        const timer = setInterval( getData, 30 * 1000 );

        return () => clearInterval( timer );

    }, [] );

    return (
        <CurrencyContext.Provider value={ { coins, rates } } >
            {children }
        </CurrencyContext.Provider>
    );
}

export default CurrencyProvider;
