import React from 'react'
import Accordion from "./Accordion"
//import PropTypes from 'prop-types'

function AccordionList({data}) {
    return (
        <>
           {data && data.map((val,index) =><Accordion key={`${val.title.substring(0,index)}${index}`} {...val} />)} 
        </>
    )
}

AccordionList.propTypes = {

}

export default AccordionList

