import React from 'react'
import PropTypes from 'prop-types'
import "./Timeline.scss"
//for now we are only supporting the right side
//TODO -> upgrade the component to let timeline appear on alternate side 
function Timeline({data}) {
    return (
        <div className="timeline">
            {data && data.map((info,index)=>(
            <div key={`${info.substring(index,6)}_tim_${index}`} className={`container right fixed pseu-${index+1}`}>
                <div className="content">
                  <p>{info}</p>
               </div>
              </div>
            ))}
        </div>
    )
}

Timeline.propType = {
    data:PropTypes.array,
}

export default Timeline

