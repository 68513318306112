import React, { useState, useRef } from 'react';
import { useSwipeable } from "react-swipeable";
import "./Carousel.scss";

function Carousel ( { items, scale = 0.9 } )
{
    const widthRef = useRef( null );
    const [ state, setState ] = useState( { index: 0, transform: "" } );

    const goTo = index =>
    {
        if ( widthRef && widthRef.current )
        {
            const { width } = widthRef.current.getBoundingClientRect();
            setState( { index, transform: `translateX(${ ( -index * width * scale ) }px )` } );
        }
    };

    const handlers = useSwipeable(
        {
            onSwipedRight: () => 
            {
                if ( state.index !== 0 )
                {
                    goTo( state.index - 1 );
                }
            },
            onSwipedLeft: () => 
            {
                if ( state.index !== items.length - 1 )
                {
                    goTo( state.index + 1 );
                }
            },
            preventDefaultTouchmoveEvent: true,
            trackMouse: true
        } );


    return (
        <div { ...handlers } className="wrapper">
            <section ref={ widthRef } style={ { transform: state.transform } } className="slider">

                {
                    items.map( ( slide, i ) => <article
                        key={ i }
                        className="slide" >{ slide }</article> )
                }

            </section>
            <div className="controls">
                {
                    items.map( ( _, i ) => <button
                        key={ i }
                        className={ i === state.index ? "active" : null }
                        onClick={ () => { goTo( i ); } }>
                    </button> )
                }
            </div>
        </div>
    );
}

export default Carousel;
