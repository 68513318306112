
  export function appReducer(state, action) {
    switch (action.type) {
      case "SHOW_HEADER":
        return { ...state,isHeaderShown:true};
    case "HIDE_HEADER":
        return { ...state, isHeaderShown:false };
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  }
  