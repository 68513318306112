import React from 'react';
import "./footer.scss";
import { Link } from "react-router-dom";
function Footer ( props )
{
    return (
        <footer>
            <div className="wrap">
                <div className="inner">
                    <div className="nav">
                        <ul>
                            <li className="ft_title">Supported coins</li>
                            <li>Bitcoin</li>
                            <li>Ethereum</li>
                            <li>USDT</li>
                        </ul>
                        <ul>
                            <li className="ft_title">Learn More</li>
                            <li><Link to="/about" >About</Link></li>
                            <li>Blog</li>
                            <li><Link to="/terms" >Terms of service</Link></li>
                            <li><Link to="/privacy" >Privacy policy</Link></li>
                        </ul>
                        <ul>
                            <li className="ft_title">Help</li>
                            <li> <Link to="/faqs" >FAQs</Link></li>
                            <li>Live chat</li>

                        </ul>
                        <ul>
                            <li className="ft_title">Contact</li>
                            <li>hello@afriswap.io</li>
                            <li>08012345678</li>
                            <li>08012345678</li>

                        </ul>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="socials">
                        <span>
                            <a href="/">
                                <img src="/images/twitter.svg" alt="twitter" />
                            </a>
                        </span>
                        <span>
                            <a href="/">
                                <img src="/images/linkedin.svg" alt="linkedin" />
                            </a>
                        </span>
                        <span>
                            <a href="/">
                                <img src="/images/insta.svg" alt="instagram" />
                            </a>
                        </span>
                        <span>
                            <a href="/">
                                <img src="/images/facebook.svg" alt="facebook" />
                            </a>
                        </span>

                        <span>
                            <a href="/">
                                <img src="/images/whatsapp.svg" alt="whatsapp" />
                            </a>
                        </span>
                    </div>

                </div>
            </div>
            <div className="copyright">
                <p>2020. Afriswap Technologies Ltd. All rights reserved</p>
            </div>
        </footer>
    );
}

Footer.propTypes = {

};

export default Footer;
