import React, { useState, useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import { CurrencyContext } from "../../../helpers/Context";

import "./header.scss";

const formatPrice = number =>
{
    const value = new Intl.NumberFormat( 'en-GB' ).format( number );

    return `₦${ value }`;
};


function Header ( props )
{
    const { pathname } = useLocation();
    const { rates } = useContext( CurrencyContext );
    const [ isMobileMenu, setIsMobileMenu ] = useState( false );
    const closeMobileMenu = () => setIsMobileMenu( false );
    const openMobileMenu = () => setIsMobileMenu( true );
    return (
        <>
            <header className="header">
                <nav className="menu">
                    <div className="menu-open" onClick={ openMobileMenu }>
                        <span><i className="fa fa-bars"></i></span>
                    </div>
                    <div className="logo">
                        <Link to="/">
                            <img src="/images/afriswap_logo.svg" alt="afriswap logo" />
                        </Link>

                    </div>
                    <ul className={ `${ isMobileMenu ? "open" : "" }` }>
                        <span className="close" onClick={ closeMobileMenu }><i className="fa fa-times"></i></span>
                        <Link to="" >
                            <li>Home</li>
                        </Link>
                        <Link to="/about">
                            <li>About </li>
                        </Link>
                        <Link to="/faqs">
                            <li>FAQ</li>
                        </Link>
                    </ul>
                </nav>
                <nav>
                    <ul className="right-nav">
                        <li>
                            <select className="btn-ghost btn-round rate"  >
                                {
                                    rates.map( c => <option
                                        key={ c?.name }
                                        value={ c?.name } >
                                        1 { c?.crypto } = { formatPrice( c?.price ) }
                                    </option>
                                    )
                                }

                            </select>
                        </li>

                        <li><Link to="/app/?qstep=0" className="btn btn-launch">Launch App</Link></li>
                    </ul>

                </nav>
            </header>

            {
                pathname === "/" ?
                    <select className="btn-ghost btn-round rate hidden-large"  >
                        {
                            rates.map( c => <option
                                key={ c?.name }
                                value={ c?.name } >
                                1 { c?.crypto } = { formatPrice( c?.price ) }
                            </option>
                            )
                        }

                    </select>
                    : null
            }


        </>
    );
}

Header.propTypes = {

};

export default Header;
