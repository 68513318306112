import React, { useEffect } from "react";
import { AppLayout /*ScrollTop PrivateRoute PublicRoute*/ } from "./component";
import { Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./component/ScrollToTop";
import { About, Faqs, Home, Terms, Privacy, NotFound } from "./pages";
import { history } from "./helpers";
import { FullSwap } from "@afriswap/rate-converter";
import "../node_modules/@afriswap/rate-converter/dist/index.css";
import "./App.scss";
import { useAppDispatch, showHeader, hideHeader } from "./factory/AppFactory";


function getCallBackUrl ()
{
  let Url = process.env.PUBLIC_URL || window.location.protocol + "//" + window.location.host;
  return Url.endsWith( "/" ) ? Url + "app" : Url + "/app";

}

function App ()
{



  function openChat(){
    const chatbtn =  document.querySelector(".IconLauncher__CircleLauncher-iwe587-2");;
    if(chatbtn){
      chatbtn.click()
    }
   }

  const pages = [
    {
      path: "",
      page: <Home />,
    },
    {
      path: "about",
      page: <About />,
    },
    {
      path: "faqs",
      page: <Faqs />,
    },
    {
      path: "terms",
      page: <Terms />,
    },
    {
      path: "privacy",
      page: <Privacy />,
    },
    {
      path: "app",
      page: <NoHeader component={ FullSwap } hideChat callbackUrl={ getCallBackUrl() } openChat={openChat} />,
    },
  ];

  return (
    <Router history={ history }>
      <AppLayout>
        <Switch>

          {
            pages.map( ( p, i ) => (
              <Route key={ i } exact path={ `/${ p.path }` }>
                <ScrollToTop />
                { p.page }
              </Route>
            ) )
          }

          {/* <PrivateRoute path="/dashboard" component={dashboard} /> */ }
          {/* <Route path="/story" component={Story} /> */ }

          <Route path="*">
            <ScrollToTop />
            <NotFound />
          </Route>

        </Switch>
      </AppLayout>
    </Router>
  );
}

function NoHeader ( { component: Component, ...rest } )
{
  const dispatch = useAppDispatch();
  useEffect( () =>
  {
    hideHeader( dispatch );
    return () =>
    {
      showHeader( dispatch );
    };
    //eslint-disable-next-line
  }, [] );
  return <Component { ...rest } />;
}
export default App;
