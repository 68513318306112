import React from "react";
import PageTitle from "./PageTitle";
//import PropTypes from 'prop-types'

function About ( props )
{
  return (
    <article className="info_article">
      <PageTitle title="About Us" />
      <div className="inner">

        <section>
          <p>
            Afriswap is a crypto and digital asset market place enabling users to securely buy and sell these assets without the need for sign up or login. Our platform eliminates:
        </p>
        </section>


        <ul>
          <li>The need to fill out long and complicated forms</li>
          <li>Submit documents</li>
          <li>and other difficult tasks and processes related to buying and selling crypto.</li>
        </ul>

        <section>
          <p>
            We want to make your crypto and digital asset onboarding experience easy, secure, fast and seamless.
            </p>
        </section>


        <section>
          <p>
            We’re a remote-first team based in Lagos Nigeria with many years of combined experience in blockchain, fintech, banking, financial services, capital markets, software development, product/project management, business development, sales, marketing and many other areas.
             </p>
        </section>

        <section>
          <p>
            Our vision is a world where crypto onboarding and use is easy, secure, fast and seamless for the average man or woman on the street.
             </p>
        </section>
        <section>
          <p>
            We’re on a mission to develop the infrastructure (applications, tools, processes and platforms) for seamless exchange of value between individuals and groups.
          </p>
        </section>
      </div>
    </article>
  );
}

About.propTypes = {};

export default About;
